.home {
  width: 350px;
  max-width: 95%;
  margin: 100px auto 20px;
  padding: 40px 30px 30px;
  background-color: white;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
  border-radius: 15px;
  text-align: center;
  transition: all .3s;
  position: relative;

  &__title {
    font-size: 18px;
    font-weight: bold;
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 600;
  }

  &__desc {
    padding: 0 10px;
    font-weight: 500;
    color: #8d8686;
    margin: 10px 0 40px;
  }

  &__address {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;

    img {
      margin-right: 5px;
    }
  }

  &__disconnect {
    font-size: 12px;
    cursor: pointer;
    font-weight: 600;
    display: inline-block;
    transition: all .3s;

    &:hover {
      color: #ff527c;
    }
  }

  &__link {
    display: inline-block;
    margin-bottom: 10px;
    font-weight: 600;
    background-color: #efe7e7;
    padding: 6px 20px;
    border-radius: 5px;
    transition: all .3s;

    &:hover {
      color: #FE6B8B;
    }
  }

  @media screen and (max-width: 700px) {
    margin: 50px auto 10px;
    padding: 20px 15px;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}